import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LICENCE_KEY } from "../js/Licence";
import "fullpage.js/vendors/scrolloverflow";
import ReactFullpage from "@fullpage/react-fullpage";
import Header from "../comp/comm/Header";
import MainFullPage01 from "../comp/MainFullPage01";
import MainFullPage02 from "../comp/MainFullPage02";
import MainFullPage03 from "../comp/MainFullPage03";
import MainFullPage04 from "../comp/MainFullPage04";
import MainFullPage05 from "../comp/MainFullPage05";
import MainFullPage06 from "../comp/MainFullPage06";

function Main() {
  const dispatch = useDispatch();
  const [animation01, setAnimation01] = useState(null);
  const [animation02, setAnimation02] = useState(null);
  const [animation03, setAnimation03] = useState(null);
  const [animation04, setAnimation04] = useState(null);
  const [animation05, setAnimation05] = useState(null);
  const [animation06, setAnimation06] = useState(null);

  const onVisibility01Change = (isVisible) => {
    if (isVisible) {
      setAnimation01("1");
    }
  };

  const onVisibility02Change = (isVisible) => {
    if (isVisible) {
      setAnimation02("1");
    }
  };

  const onVisibility03Change = (isVisible) => {
    if (isVisible) {
      setAnimation03("1");
    }
  };

  const onVisibility04Change = (isVisible) => {
    if (isVisible) {
      setAnimation04("1");
    }
  };

  const onVisibility05Change = (isVisible) => {
    if (isVisible) {
      setAnimation05("1");
    }
  };

  const onVisibility06Change = (isVisible) => {
    if (isVisible) {
      setAnimation06("1");
    }
  };

  const anchors = [
    "HOME",
    "What_ADB?",
    "Members",
    "With_ADB",
    "History",
    "Directions",
  ];

  useEffect(
    () => {
      dispatch({ type: "loadingChange", payload: true });

      setTimeout(() => {
        dispatch({ type: "loadingChange", payload: false });
      }, 500);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <ReactFullpage
        anchors={anchors}
        navigation
        navigationTooltips={anchors}
        licenseKey={LICENCE_KEY.fullPage}
        scrollOverflow={true}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              <div className="section">
                <Header fullpageApi={fullpageApi} />
                <MainFullPage01
                  animation={animation01}
                  onVisibilityChange={onVisibility01Change}
                />
              </div>
              <div className="section">
                <MainFullPage02
                  animation={animation02}
                  onVisibilityChange={onVisibility02Change}
                />
              </div>
              <div className="section">
                <MainFullPage03
                  animation={animation03}
                  onVisibilityChange={onVisibility03Change}
                />
              </div>
              <div className="section">
                <MainFullPage04
                  animation={animation04}
                  onVisibilityChange={onVisibility04Change}
                />
              </div>
              <div className="section">
                <MainFullPage05
                  animation={animation05}
                  onVisibilityChange={onVisibility05Change}
                />
              </div>
              <div className="section page06-bg">
                <MainFullPage06
                  animation={animation06}
                  onVisibilityChange={onVisibility06Change}
                />
              </div>
            </div>
          );
        }}
      />
    </>
  );
}

export default Main;
