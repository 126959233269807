import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function MainFullPage02(props) {
  const [business, setBusiness] = useState("data");

  return (
    <div className="d-flex h-100">
      <div className="col justify-content-center align-self-center text-center">
        <div className="container">
          <div className="row">
            <VisibilitySensor
              scrollCheck={true}
              onChange={props.onVisibilityChange}
              delayedCall
            >
              <div
                className={`col-12 eng-font main-sub-title opacity-0 ${
                  props.animation === "1"
                    ? "animated animatedFadeInUp fadeInUp"
                    : ""
                }`}
              >
                What is <span>AUTODATABANK?</span>
              </div>
            </VisibilitySensor>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-1 col-lg-2"></div>
            <div
              className={`col-sm-12 col-md-10 col-lg-8 font-grey opacity-0 ${
                props.animation === "1"
                  ? "animated animatedFadeInUp fadeInUp"
                  : ""
              }`}
            >
              (주) 오토데이타뱅크는 자동차 정비 관련 프로그램을 판매·공급하는
              업체들이 자동차 데이터와 비즈니스 네트워크를 활용하여 자동차
              정비문화의 신뢰성과 중고차 거래의 투명성을 제고하는 등
              사회적·경제적 가치 창출을 위한 공동사업을 영위하고자 설립한 자동차
              종합 정보 플랫폼 운영 회사입니다.
            </div>
            <div className="col-sm-12 col-md-1 col-lg-2"></div>
          </div>
          <div className="row pt-5">
            <div className="col-sm-12 col-md-12 col-lg-7">
              {business === "data" ? (
                <img
                  src="../assets/img_data.png"
                  alt=""
                  className="img-fluid"
                />
              ) : (
                <img
                  src="../assets/img_inno.png"
                  alt=""
                  className="img-fluid"
                />
              )}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5">
              <div className="row">
                <div className="col-12 sub-sub-title">
                  <span className="eng-font">Major business</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <hr style={{ borderWidth: "2px" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-12 business-box02 ${
                    business === "data" && "business-selected-box"
                  }`}
                  onClick={() => setBusiness("data")}
                >
                  <span>데이터 중개</span>
                  <span>고객 동의 기반 자동차 정비이력 데이터 중개</span>
                  {business === "data" && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "http://www.econovill.com/news/articleView.html?idxno=570814"
                        );
                      }}
                    >
                      자세히 보기
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-12 business-box02 ${
                    business === "inno" && "business-selected-box"
                  }`}
                  onClick={() => setBusiness("inno")}
                >
                  <span>전문 폐쇄몰 운영</span>
                  <span>
                    25,000여 정비사업자들을 대상으로 한<br />
                    자동차 부품용품 폐쇄몰 및 자동차 종합정보 제공 플랫폼 운영
                  </span>
                  {business === "inno" && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          "https://m.blog.naver.com/dmc201/221658529144"
                        );
                      }}
                    >
                      자세히 보기
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFullPage02;
