import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <header>
      <Navbar expand="lg" className="navbar-expand-lg navigation" id="navbar">
        <Container>
          <Navbar.Brand
            as={Link}
            onClick={() => props.fullpageApi.moveTo(1)}
            to="/"
          >
            <img
              src="/assets/h_logo01.png"
              alt=""
              className="img-fluid img-logo"
            />
          </Navbar.Brand>

          <Navbar.Collapse className="navbar-collapse" id="navbarmain">
            <Nav className="ml-auto">
              <Link
                to="/"
                onClick={() => props.fullpageApi.moveTo(2)}
                className="top-menu"
              >
                About ADB
              </Link>

              <Link
                to="/"
                onClick={() => props.fullpageApi.moveTo(3)}
                className="top-menu"
              >
                Members
              </Link>

              <Link
                to="/"
                onClick={() => props.fullpageApi.moveTo(4)}
                className="top-menu"
              >
                With ADB
              </Link>

              <Link
                to="/"
                onClick={() => props.fullpageApi.moveTo(5)}
                className="top-menu"
              >
                History
              </Link>

              <Link
                to="/"
                onClick={() => props.fullpageApi.moveTo(6)}
                className="top-menu"
              >
                Directions
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
