import React, { useRef, useEffect } from "react";

import "./Accordion.css";

function Accordion(props) {
  const content = useRef(null);

  function toggleAccordion() {
    props.setActiveState(props.setActive === "" ? "active" : "");
    props.setHeightState(
      props.setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  useEffect(
    () => {
      props.setHeightState(
        props.setActive === "active"
          ? `${content.current.scrollHeight}px`
          : "0px"
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="accordion__section">
      <button
        className={`accordion ${props.setActive}`}
        onClick={toggleAccordion}
      >
        <table className="info-title">
          <tbody>
            <tr>
              <td className="accordion__title">{props.title}</td>
            </tr>
          </tbody>
        </table>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${props.setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </div>
  );
}

export default Accordion;
