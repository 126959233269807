import { useState, useEffect } from "react";
import Location from "../comp/comm/Location";
import Accordion from "../comp/comm/Accordion";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import VisibilitySensor from "react-visibility-sensor";
import "swiper/css";

function MainFullPage05(props) {
  SwiperCore.use([Autoplay]);

  const [setActive01, setActiveState01] = useState("active");
  const [setActive02, setActiveState02] = useState("");
  const [setActive03, setActiveState03] = useState("");
  const [setActive04, setActiveState04] = useState("");
  const [setHeight01, setHeightState01] = useState("0px");
  const [setHeight02, setHeightState02] = useState("0px");
  const [setHeight03, setHeightState03] = useState("0px");
  const [setHeight04, setHeightState04] = useState("0px");

  const directionDescr = [
    {
      title: "천호대로 이용 시",
      descr:
        "1. 천호대교 강일 구리 방면으로 좌측 도로 이용<br />2. 지하차도 진입 후 직진<br />3. 조정대로 미사경정장 방면으로 지하차도 진입<br />4. 미사대로 시청, 팔당대교 방면으로 우회전<br />5. 두번째 건물 주차장으로 들어오시면 됩니다.",
    },
    {
      title: "올림픽대로 이용 시",
      descr:
        "1. 올림픽대로 하남시청 팔당대교 방면으로 우측고속화 도로 진출<br />2. 미사대로 직진<br />3. 첫번째 지하차도(미사지하차도) 통과 후 우측 차로로 진입<br />4. 교차로 통과 후 두번째 건물 주차장으로 들어오시면 됩니다.",
    },
    {
      title: "수도권 제1순환고속도로 이용 시(~구리 방면)",
      descr:
        "1. 하남 천호대교 방면으로 진출<br />2. 조정대로 미사경정장 방면으로 지하차도 진입<br />3. 미사대로 시청, 팔당대교 방면으로 우회전<br />4. 두번째 건물 주차장으로 들어오시면 됩니다.",
    },
    {
      title: "수도권 제1순환고속도로 이용 시(~하남 방면)",
      descr:
        "1. 팔당대교 춘천 서울춘천 고속도로 방면으로 좌측 도로 이동<br />2. 올림픽대로 하남시청 팔당대교 방면으로 우측 고속화도로 진출<br />3. 미사대로 직진<br />4. 첫번째 지하차도(미사지하차도) 통과 후 우측 차로로 진입<br />5. 교차로 통과 후 두번째 건물 주차장으로 들어오시면 됩니다.",
    },
  ];

  useEffect(
    () => {
      if (setActive01 === "active") {
        setActiveState02("");
        setActiveState03("");
        setActiveState04("");
        setHeightState02("0px");
        setHeightState03("0px");
        setHeightState04("0px");
      }
    },
    // eslint-disable-next-line
    [setActive01]
  );

  useEffect(
    () => {
      if (setActive02 === "active") {
        setActiveState01("");
        setActiveState03("");
        setActiveState04("");
        setHeightState01("0px");
        setHeightState03("0px");
        setHeightState04("0px");
      }
    },
    // eslint-disable-next-line
    [setActive02]
  );

  useEffect(
    () => {
      if (setActive03 === "active") {
        setActiveState01("");
        setActiveState02("");
        setActiveState04("");
        setHeightState01("0px");
        setHeightState02("0px");
        setHeightState04("0px");
      }
    },
    // eslint-disable-next-line
    [setActive03]
  );

  useEffect(
    () => {
      if (setActive04 === "active") {
        setActiveState01("");
        setActiveState02("");
        setActiveState03("");
        setHeightState01("0px");
        setHeightState02("0px");
        setHeightState03("0px");
      }
    },
    // eslint-disable-next-line
    [setActive04]
  );

  return (
    <>
      <div className="d-flex">
        <div className="col justify-content-center align-self-center text-center">
          <div className="container">
            <div className="row">
              <VisibilitySensor
                scrollCheck={true}
                onChange={props.onVisibilityChange}
                delayedCall
              >
                <div
                  className={`col-12 eng-font main-sub-title pt-5 opacity-0 ${
                    props.animation === "1"
                      ? "animated animatedFadeInUp fadeInUp"
                      : ""
                  }`}
                >
                  Direction to <span>AUTODATABANK</span>
                </div>
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </div>
      <div className="directions-box">
        <div>
          <Location />
        </div>
        <div>
          <div className="d-flex h-100">
            <div className="col justify-content-center align-self-center">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <ul className="directions-title">
                      <li>
                        <img src="../assets/img_adb.png" alt="" />
                      </li>
                      <li>
                        <h5>(주)오토데이타뱅크</h5>
                        <p>
                          경기도 하남시 미사대로 520, 현대지식산업센터 한강미사
                          2차 C동 7층 721호
                          <br />
                          simon.cha@kadb.co.kr
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pt-5">
                    <h5 className="font-orange">자가용 이용 시</h5>
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-12 accd-wrap">
                    <Accordion
                      title={directionDescr[0].title}
                      content={directionDescr[0].descr}
                      setActive={setActive01}
                      setActiveState={setActiveState01}
                      setHeight={setHeight01}
                      setHeightState={setHeightState01}
                    />
                    <Accordion
                      title={directionDescr[1].title}
                      content={directionDescr[1].descr}
                      setActive={setActive02}
                      setActiveState={setActiveState02}
                      setHeight={setHeight02}
                      setHeightState={setHeightState02}
                    />
                    <Accordion
                      title={directionDescr[2].title}
                      content={directionDescr[2].descr}
                      setActive={setActive03}
                      setActiveState={setActiveState03}
                      setHeight={setHeight03}
                      setHeightState={setHeightState03}
                    />
                    <Accordion
                      title={directionDescr[3].title}
                      content={directionDescr[3].descr}
                      setActive={setActive04}
                      setActiveState={setActiveState04}
                      setHeight={setHeight04}
                      setHeightState={setHeightState04}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-5">
                    <h5 className="font-orange">대중교통 이용 시</h5>
                  </div>
                </div>

                <div className="row pt-2">
                  <div className="col-12 accd-wrap ml-3">
                    1. 5호선 하남풍산역 하차
                    <br />
                    2. 5번 또는 8번 출구로 나와서 직진 이동
                    <br />
                    3. 농협건물을 지나 직진하여 한강미사1차 건물 경유
                    <br />
                    4. 1차 건물 지나서 2차 건물 C동으로 들어오시면 됩니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide-banner-box">
        <Swiper
          spaceBetween={50}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 50,
            },
            1440: {
              slidesPerView: 7,
              spaceBetween: 50,
            },
            1600: {
              slidesPerView: 8,
              spaceBetween: 50,
            },
            1920: {
              slidesPerView: 9,
              spaceBetween: 50,
            },
          }}
          loop={true}
        >
          <SwiperSlide>
            <img src="../assets/partner/logo_AJ.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_audatex.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_autobegins.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_beogeum.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_carpet.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_emeye.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_hyundai.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_kcar.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_nicedR.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_partzone.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_sknetworks.png" alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="../assets/partner/logo_SKtire.png" alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="footer d-flex">
        <div className="col justify-content-center align-self-center text-center">
          <div className="container">
            <div className="row">
              <div className="col-5 text-left">
                <img src="../assets/f_logo.png" alt="" className="img-fluid" />
              </div>
              <div className="col-7 text-right footer-text">
                2022 AutoDataBank, Inc. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainFullPage05;
