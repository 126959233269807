import { useRef, useEffect } from "react";

const options = {
  center: new window.kakao.maps.LatLng(37.5589, 127.204606), //지도의 중심좌표.
  level: 3, //지도의 레벨(확대, 축소 정도)
};

function Location() {
  const container = useRef(null); //지도를 담을 영역의 DOM 레퍼런스

  useEffect(() => {
    var map = new window.kakao.maps.Map(container.current, options); //지도 생성 및 객체 리턴
    var markerPosition = new window.kakao.maps.LatLng(37.5589, 127.204606);
    var marker = new window.kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);
  }, []);

  return <div className="map" ref={container}></div>;
}

export default Location;
