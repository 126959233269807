import VisibilitySensor from "react-visibility-sensor";

function MainFullPage03(props) {
  return (
    <div className="page03-img d-flex h-100">
      <div className="col justify-content-center align-self-center text-center">
        <div className="custom-container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="container">
                <div className="row">
                  <div
                    className={`col-12 main-sub-title2 opacity-0 ${
                      props.animation === "1"
                        ? "animated animatedFadeInUp fadeInRight"
                        : ""
                    }`}
                  >
                    <VisibilitySensor
                      scrollCheck={true}
                      onChange={props.onVisibilityChange}
                      delayedCall
                    >
                      <span>Members of</span>
                    </VisibilitySensor>
                    <span>AUTODATABANK</span>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col-12 pt-4 main-descr opacity-0 ${
                      props.animation === "1"
                        ? "animated animatedFadeInUp fadeInRight"
                        : ""
                    }`}
                  >
                    (주)오토데이타뱅크는 자동차 정비업게 관련 베테랑 업체들과
                    제휴하여 최상의 품질을 유지·관리하고 있습니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>극동시스템</h3>
                        <img src="../assets/logo_kukdong.png" alt="" />
                        <h5>주요사업</h5>
                        <p>프로 판매/재고/회계시스템 개발</p>
                        <h5>자동차정비솔루션</h5>
                        <p>PsimCAR</p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>삼영시스템즈</h3>
                        <img src="../assets/logo_samyoung.png" alt="" />
                        <h5>주요사업</h5>
                        <p>하이스피드 자동차 정비 프로그램 개발</p>
                        <h5>자동차정비솔루션</h5>
                        <p>하이스피드</p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>아델정보</h3>
                        <img src="../assets/logo_adel.png" alt="" />
                        <h5>주요사업</h5>
                        <p>
                          자동차 정비 프로그램 개발
                          <br />
                          사이버카, 닥터, SAM 등 다수 프로그램 개발
                        </p>
                        <h5>자동차정비솔루션</h5>
                        <p>사이버카</p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>인트라밴</h3>
                        <img src="../assets/logo_intravan.png" alt="" />
                        <h5>주요사업</h5>
                        <p>
                          자동차 정비업 경영전문 솔루션
                          <br />
                          정비업체 맞춤형 솔루션 개발
                        </p>
                        <h5>자동차정비솔루션</h5>
                        <p>비즈하이5, 네오하이웨이, 오토X</p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>케이비드</h3>
                        <img src="../assets/logo_kabead.png" alt="" />
                        <h5>주요사업</h5>
                        <p>
                          정비마법사, 철강 ERP 개발, 고객관리 프로그램 개발,
                          서비스, RIA, MIS 개발, IT 컨설팅
                        </p>
                        <h5>자동차정비솔루션</h5>
                        <p>정비마법사</p>
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                    <div className="partner-box">
                      <span>
                        <h3>텔로스소프트</h3>
                        <img src="../assets/logo_talos.png" alt="" />
                        <h5>주요사업</h5>
                        <p>
                          카매니저 자동차관리 프로그램, 타이어관리, 윤활유 관리,
                          POS, 키오스크 SW 개발
                        </p>
                        <h5>자동차정비솔루션</h5>
                        <p>카매니저</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFullPage03;
