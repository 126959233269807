import VisibilitySensor from "react-visibility-sensor";

function MainFullPage05(props) {
  return (
    <div className="page05-img d-flex h-100">
      <div className="col justify-content-center align-self-center text-center">
        <div className="container">
          <div className="row">
            <VisibilitySensor
              scrollCheck={true}
              onChange={props.onVisibilityChange}
              delayedCall
            >
              <div
                className={`col-12 eng-font main-sub-title text-white opacity-0 ${
                  props.animation === "1"
                    ? "animated animatedFadeInUp fadeInUp"
                    : ""
                }`}
              >
                History of <span>AUTODATABANK</span>
              </div>
            </VisibilitySensor>
          </div>
          <div className="row pt-5">
            <div className="col-sm-12 col-md-6 col-lg-6 history-logo-box"></div>
            <div className="col-sm-12 col-md-6 col-lg-6 history-box">
              <ul>
                <li>
                  <span>2021.12</span>
                  나이스디앤알과 MOU 체결
                </li>
                <li>
                  <span>2019.11</span>
                  SK네트웍스 수입차 부품 주문 모바일 시스템 구축을 위한 MOU 체결
                </li>
                <li>
                  <span>2019.09</span>
                  B2B 자동차용품부품 폐쇄몰 ‘이노몰’ 오픈
                </li>
                <li>
                  <span>2019.06</span>
                  정비업소를 통한 다이렉트 보험 추천 사업 ‘굿카비즈‘ 오픈
                </li>
                <li>
                  <span>2019.06</span>
                  차량소유주 동의 기반 자동차등록원부 조회용 API 서비스 개시
                </li>
                <li>
                  <span>2019.02</span>
                  인트라밴과 'ADL' 솔루션 총판계약 체결
                </li>
                <li>
                  <span>2019.01</span>
                  B2B 광고 플랫폼 서비스 개시
                </li>
                <li>
                  <span>2018.10</span>
                  오토비긴즈와 MOU 체결
                </li>
                <li>
                  <span>2018.09</span>
                  정비전산협의회 설립
                </li>
                <li>
                  <span>2018.08</span>
                  중고차(상품용 차량) 수리이력 조회용 API 서비스 개시
                </li>
                <li>
                  <span>2017.09</span>
                  차량관리 앱 "카다이어리2" 론칭
                </li>
                <li>
                  <span>2017.05</span>
                  자동차정비프로그램을 통한 B2B 광고 서비스 개시
                </li>
                <li>
                  <span>2016.09</span>
                  회사 설립
                </li>
              </ul>
            </div>
          </div>
          <div className="row pt-5"></div>
        </div>
      </div>
    </div>
  );
}

export default MainFullPage05;
