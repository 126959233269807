import VisibilitySensor from "react-visibility-sensor";

function MainFullPage04(props) {
  return (
    <div className="page04-img d-flex h-100">
      <div className="col justify-content-center align-self-center text-center">
        <div className="custom-container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="container">
                <div className="row">
                  <div
                    className={`col-12 main-sub-title3 opacity-0 ${
                      props.animation === "1"
                        ? "animated animatedFadeInUp fadeInRight"
                        : ""
                    }`}
                  >
                    <VisibilitySensor
                      scrollCheck={true}
                      onChange={props.onVisibilityChange}
                      delayedCall
                    >
                      <span>With</span>
                    </VisibilitySensor>
                    <span>AUTODATABANK?</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-7 with-autodatabank-box">
                    <h3>고객 또는 B2C 사업자</h3>
                    <p>차계부 서비스, 예방점검 및 부품교체주기 알람</p>
                    <p>차량 생애 주기 관리 → 중고차 매매 및 자동차 금융 연결</p>
                    <p>중고차 이력 정보 → 사기 방지, 합리적인 가격에 매입</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 with-autodatabank-box">
                    <h3>중고차 매매업체</h3>
                    <p>판매딜러: 판매가격 예측</p>
                    <p>매입딜러: 합리적인 기준으로 매입</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-7 with-autodatabank-box">
                    <h3>정비업체</h3>
                    <p>타업체 방문 정비이력 조회 → 정비이력 통합관리</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 with-autodatabank-box">
                    <h3>부품업체</h3>
                    <p>부품 수요 예측 → 부품 재고관리 효율성 증가</p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-7 with-autodatabank-box">
                    <h3>제조사</h3>
                    <p>
                      주행거리에 따른 고장 및 노화 부위 파악을 통한 차량 개선
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 with-autodatabank-box">
                    <h3>보험사</h3>
                    <p>차량 이력 비교를 통한 보험료 차별화 가능</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainFullPage04;
