import { Route, Switch, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ReactGA from "react-ga";

import ScrollToTop from "./comp/comm/ScrollToTop";
import Main from "./comp/Main";
import Page404 from "./comp/comm/Page404";
import Loading from "./comp/comm/Loading";

function App(props) {
  const store = useSelector((state) => state);
  const history = useHistory();

  useEffect(
    () => {
      ReactGA.initialize("G-X547ZS9JGY");
      history.listen((location) => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      });
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="App">
      <Loading loading={store.rdcLoading} />

      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Main} />

          {/* 없는 페이지 */}
          <Route path="/:id" component={Page404} />
        </Switch>
      </ScrollToTop>
    </div>
  );
}

export default App;
