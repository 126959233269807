import VisibilitySensor from "react-visibility-sensor";

function MainFullPage01(props) {
  return (
    <div className="main-img d-flex h-100">
      <div className="col justify-content-center align-self-center text-center">
        <VisibilitySensor
          scrollCheck={true}
          onChange={props.onVisibilityChange}
          delayedCall
        >
          <span
            className={`main-title-txt text-white opacity-0 ${
              props.animation === "1"
                ? "animated animatedFadeInUp fadeInUp"
                : ""
            }`}
          >
            자동차 종합정보 플랫폼
          </span>
        </VisibilitySensor>

        <br />
        <span
          className={`main-title-txt02 text-white eng-font opacity-0 ${
            props.animation === "1" ? "animated animatedFadeInUp fadeInUp" : ""
          }`}
        >
          <span>A</span>
          UTO
          <span>D</span>
          ATA
          <span>B</span>
          ANK, Inc.
        </span>
      </div>

      <div className="main-mouse-img">
        <div>
          <img src="../assets/img_mouse.png" className="move-mouse" alt="" />
        </div>
        <div>
          <img src="../assets/img_line.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default MainFullPage01;
